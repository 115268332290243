// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/models/lending/credit_line_agreement.proto (package kikoff_canada.protobuf.models.lending, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message kikoff_canada.protobuf.models.lending.CreditLineAgreement
 */
export class CreditLineAgreement extends Message<CreditLineAgreement> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * @generated from field: uint32 minimum_payment_amount_cents = 3;
   */
  minimumPaymentAmountCents = 0;

  /**
   * @generated from field: uint32 minimum_payment_amount_percent = 4;
   */
  minimumPaymentAmountPercent = 0;

  /**
   * @generated from field: uint32 default_credit_limit_cents = 5;
   */
  defaultCreditLimitCents = 0;

  constructor(data?: PartialMessage<CreditLineAgreement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.models.lending.CreditLineAgreement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "minimum_payment_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "minimum_payment_amount_percent", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "default_credit_limit_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditLineAgreement {
    return new CreditLineAgreement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditLineAgreement {
    return new CreditLineAgreement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditLineAgreement {
    return new CreditLineAgreement().fromJsonString(jsonString, options);
  }

  static equals(a: CreditLineAgreement | PlainMessage<CreditLineAgreement> | undefined, b: CreditLineAgreement | PlainMessage<CreditLineAgreement> | undefined): boolean {
    return proto3.util.equals(CreditLineAgreement, a, b);
  }

  static _enums: {
  };
}


// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/types/recurrence.proto (package kikoff_canada.protobuf.types, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message kikoff_canada.protobuf.types.Recurrence
 */
export class Recurrence extends Message<Recurrence> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: kikoff_canada.protobuf.types.Recurrence.Settings settings = 2;
   */
  settings?: Recurrence.Settings;

  /**
   * @generated from field: kikoff_canada.protobuf.types.Recurrence.Attempt last_attempt = 3;
   */
  lastAttempt?: Recurrence.Attempt;

  /**
   * @generated from field: kikoff_canada.protobuf.types.Recurrence.Attempt next_attempt = 4;
   */
  nextAttempt?: Recurrence.Attempt;

  constructor(data?: PartialMessage<Recurrence>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.types.Recurrence";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "settings", kind: "message", T: Recurrence.Settings },
    { no: 3, name: "last_attempt", kind: "message", T: Recurrence.Attempt },
    { no: 4, name: "next_attempt", kind: "message", T: Recurrence.Attempt },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Recurrence {
    return new Recurrence().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Recurrence {
    return new Recurrence().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Recurrence {
    return new Recurrence().fromJsonString(jsonString, options);
  }

  static equals(a: Recurrence | PlainMessage<Recurrence> | undefined, b: Recurrence | PlainMessage<Recurrence> | undefined): boolean {
    return proto3.util.equals(Recurrence, a, b);
  }

  static _enums: {
    Interval: typeof Recurrence.Interval,
  };
}

export namespace Recurrence {
  /**
   * @generated from enum kikoff_canada.protobuf.types.Recurrence.Interval
   */
  export enum Interval {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: MONTHLY = 1;
     */
    MONTHLY = 1,
  }
  // Retrieve enum metadata with: proto3.getEnumType(Recurrence.Interval)
  proto3.util.setEnumType(Recurrence.Interval, "kikoff_canada.protobuf.types.Recurrence.Interval", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "MONTHLY" },
  ]);
}

export namespace Recurrence {
  /**
   * @generated from message kikoff_canada.protobuf.types.Recurrence.Settings
   */
  export class Settings extends Message<Recurrence.Settings> {
    /**
     * @generated from field: kikoff_canada.protobuf.types.Recurrence.Settings.State state = 1;
     */
    state = Recurrence.Settings.State.UNKNOWN;

    /**
     * @generated from field: string payment_method_id = 2;
     */
    paymentMethodId = "";

    /**
     * @generated from field: kikoff_canada.protobuf.types.Recurrence.Interval interval = 3;
     */
    interval = Recurrence.Interval.UNKNOWN;

    /**
     * Offset from beginning of interval:
     *   1st of the month: 0
     *   10th of the month: 9
     *   Sunday: 0
     *   Wednesday: 3
     *
     * @generated from field: uint32 offset = 4;
     */
    offset = 0;

    constructor(data?: PartialMessage<Recurrence.Settings>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "kikoff_canada.protobuf.types.Recurrence.Settings";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(Recurrence.Settings.State) },
      { no: 2, name: "payment_method_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "interval", kind: "enum", T: proto3.getEnumType(Recurrence.Interval) },
      { no: 4, name: "offset", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Recurrence.Settings {
      return new Recurrence.Settings().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Recurrence.Settings {
      return new Recurrence.Settings().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Recurrence.Settings {
      return new Recurrence.Settings().fromJsonString(jsonString, options);
    }

    static equals(a: Recurrence.Settings | PlainMessage<Recurrence.Settings> | undefined, b: Recurrence.Settings | PlainMessage<Recurrence.Settings> | undefined): boolean {
      return proto3.util.equals(Recurrence.Settings, a, b);
    }

    static _enums: {
      State: typeof Recurrence.Settings.State,
    };
  }
}

export namespace Recurrence.Settings {
  /**
   * @generated from enum kikoff_canada.protobuf.types.Recurrence.Settings.State
   */
  export enum State {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: ENABLED = 1;
     */
    ENABLED = 1,

    /**
     * @generated from enum value: DISABLED = 2;
     */
    DISABLED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(Recurrence.Settings.State)
  proto3.util.setEnumType(Recurrence.Settings.State, "kikoff_canada.protobuf.types.Recurrence.Settings.State", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "ENABLED" },
    { no: 2, name: "DISABLED" },
  ]);
}

export namespace Recurrence {
  /**
   * @generated from message kikoff_canada.protobuf.types.Recurrence.Attempt
   */
  export class Attempt extends Message<Recurrence.Attempt> {
    /**
     * @generated from field: kikoff_canada.protobuf.types.Recurrence.Attempt.Status status = 1;
     */
    status = Recurrence.Attempt.Status.UNKNOWN;

    /**
     * @generated from field: google.protobuf.Timestamp at = 2;
     */
    at?: Timestamp;

    /**
     * @generated from field: uint32 amount_cents = 3;
     */
    amountCents = 0;

    constructor(data?: PartialMessage<Recurrence.Attempt>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "kikoff_canada.protobuf.types.Recurrence.Attempt";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(Recurrence.Attempt.Status) },
      { no: 2, name: "at", kind: "message", T: Timestamp },
      { no: 3, name: "amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Recurrence.Attempt {
      return new Recurrence.Attempt().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Recurrence.Attempt {
      return new Recurrence.Attempt().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Recurrence.Attempt {
      return new Recurrence.Attempt().fromJsonString(jsonString, options);
    }

    static equals(a: Recurrence.Attempt | PlainMessage<Recurrence.Attempt> | undefined, b: Recurrence.Attempt | PlainMessage<Recurrence.Attempt> | undefined): boolean {
      return proto3.util.equals(Recurrence.Attempt, a, b);
    }

    static _enums: {
      Status: typeof Recurrence.Attempt.Status,
    };
  }
}

export namespace Recurrence.Attempt {
  /**
   * @generated from enum kikoff_canada.protobuf.types.Recurrence.Attempt.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILURE = 2;
     */
    FAILURE = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(Recurrence.Attempt.Status)
  proto3.util.setEnumType(Recurrence.Attempt.Status, "kikoff_canada.protobuf.types.Recurrence.Attempt.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILURE" },
  ]);
}


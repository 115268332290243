// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/models/lending/credit_line.proto (package kikoff_canada.protobuf.models.lending, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { CreditLineAgreement } from "./credit_line_agreement_pb.js";
import { Recurrence } from "../../types/recurrence_pb.js";

/**
 * @generated from message kikoff_canada.protobuf.models.lending.CreditLine
 */
export class CreditLine extends Message<CreditLine> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: sint32 balance_cents = 2;
   */
  balanceCents = 0;

  /**
   * @generated from field: uint32 credit_limit_cents = 3;
   */
  creditLimitCents = 0;

  /**
   * @generated from field: uint32 minimum_payment_due_cents = 4;
   */
  minimumPaymentDueCents = 0;

  /**
   * @generated from field: uint32 outstanding_minimum_payment_cents = 5;
   */
  outstandingMinimumPaymentCents = 0;

  /**
   * @generated from field: google.protobuf.Timestamp payment_due_at = 6;
   */
  paymentDueAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp next_statement_closing_at = 7;
   */
  nextStatementClosingAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp opened_at = 8;
   */
  openedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp closed_at = 9;
   */
  closedAt?: Timestamp;

  /**
   * @generated from field: kikoff_canada.protobuf.models.lending.CreditLineAgreement agreement = 10;
   */
  agreement?: CreditLineAgreement;

  /**
   * @generated from field: kikoff_canada.protobuf.types.Recurrence autopay = 11;
   */
  autopay?: Recurrence;

  /**
   * @generated from field: bool allow_closure = 12;
   */
  allowClosure = false;

  /**
   * @generated from field: bool setup_complete = 13;
   */
  setupComplete = false;

  /**
   * TODO
   *
   * @generated from field: google.protobuf.Timestamp next_furnishing_at = 14;
   */
  nextFurnishingAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp first_payment_scheduled_at = 15;
   */
  firstPaymentScheduledAt?: Timestamp;

  constructor(data?: PartialMessage<CreditLine>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.models.lending.CreditLine";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "balance_cents", kind: "scalar", T: 17 /* ScalarType.SINT32 */ },
    { no: 3, name: "credit_limit_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "minimum_payment_due_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "outstanding_minimum_payment_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "payment_due_at", kind: "message", T: Timestamp },
    { no: 7, name: "next_statement_closing_at", kind: "message", T: Timestamp },
    { no: 8, name: "opened_at", kind: "message", T: Timestamp },
    { no: 9, name: "closed_at", kind: "message", T: Timestamp },
    { no: 10, name: "agreement", kind: "message", T: CreditLineAgreement },
    { no: 11, name: "autopay", kind: "message", T: Recurrence },
    { no: 12, name: "allow_closure", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "setup_complete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "next_furnishing_at", kind: "message", T: Timestamp },
    { no: 15, name: "first_payment_scheduled_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditLine {
    return new CreditLine().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditLine {
    return new CreditLine().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditLine {
    return new CreditLine().fromJsonString(jsonString, options);
  }

  static equals(a: CreditLine | PlainMessage<CreditLine> | undefined, b: CreditLine | PlainMessage<CreditLine> | undefined): boolean {
    return proto3.util.equals(CreditLine, a, b);
  }

  static _enums: {
  };
}

